@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
@import url(https://cdn.jsdelivr.net/npm/codemirror@5.61.0/lib/codemirror.min.css);
@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
/*  WEB FONTS */

/* MAIN THEME */

body {
  padding: 0px;
  margin: 0px;
  background-color: black;
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* empty contanier */
.empty {
  margin: 0px;
  padding: 0px;
}


/* fonts  */

h1, h2, h3 {
  font-family: 'Fjalla One', sans-serif;
  letter-spacing: 2px;
  white-space: nowrap;
}

p, li {
  font-family: sans-serif;
  letter-spacing: 0.1em;
}

blockquote {
  font-family: 'Fjalla One', sans-serif;
  font-size: large;
  letter-spacing: 0.05em;

}

/* translucent screen */

.fadeScreen {
  background-color: rgba(248, 247, 216, 0);
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: background-color .5s;
}

.fadeIn {
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  transition: background-color .5s;
}


.main {
  background: url(https://res.cloudinary.com/dojuvapbw/image/upload/v1637084088/Don-Isiko-Headshot-3_fjtmjw.jpg) fixed;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0% 0% ;
  background-color:black;
  display: flex;
  min-height: 100vh;
  height: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0px;
  overflow: auto;
}

nav {
  display: flex;
  margin: 0px;
  width: auto;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow: hidden;
  transition: 0.5s;
  background-color: black;
  color: white;
  border-right: 1px solid #222324;
}

.nav-icons {
  width: auto;
  color: white;
  background-color: black;
  text-align: center;
}

.nav-icons p{
  margin: 20px;
  font-size: 1.5em;
  transition: color 1s;
}

#close i, #open i {
  color: whitesmoke;
  font-size: 1.1em;
  transition: 1s;
  margin-left: 0px;
}

#close {
  display: none;
}

#close:hover i {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  color: tomato;
}

#open:hover i {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  color: tomato;
}


.contacts {
  position: relative;
  top: 60%;
  left: 0%;
}

.contacts a {
  display: block;
  margin: 20px auto;
  color: whitesmoke;
  transition: color 1s;
}

.contacts i{
  font-size: 2em;
  width: 2em;
}

.nav-menu {
  height: 100%;
  width: 0;
  flex-grow: 1;
  background-color: black;
  padding-top: 20px;
  transition: width 1s;
}

.nav-menu-open {
  width: 200px;
  transition: width 1s;
}


.nav-menu h2 {
  margin-left: 1em;
  border-bottom: 1px solid #c4040e;
}

.nav-menu ul {
  list-style: none;
  margin-top: 40px;
}

.nav-menu li {
  margin-bottom: 1em;
  font-size: 130%;
  width: 0px;
  white-space: nowrap;
  border-bottom: 1px solid tomato;
  transition: width 1s;
}

/* STYLING TO SHOW ACTIVE PAGE: TOGGLED */
.active-page {
  width: 50px !important;
}

nav a:hover, .nav-icons p:hover {
  color: tomato;
}

nav li:hover {
  width: 50px;
}

.main-content {
  margin-left: 10%;
  padding: 10%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:flex-start;
}

.square {
  height: 400px;
  width: 450px;
  margin: 40px;
  background-color: #303030;
  box-shadow: 1px 1px 3px 1px #2b2b2b inset;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  color: whitesmoke;
}

.square i {
  color:tomato;
  font-size: 4em;
  margin: 50px;
}

.square h2 {
  margin-left: 50px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  width: 50px;
  white-space: nowrap;
  border-bottom: 3px solid tomato;
}

.square p {
  margin-left: 50px;
  line-height: 1.6;
  padding-bottom: 10px;
}

.blank {
  visibility: hidden;
  margin-bottom: 150px;
}

footer {
  display: none;
}


/* END OF MAIN THEME */

/* PORTFOLIO THEME */

.portfolio-category h2 {
  color: tomato;
}

.portfolio-category {
  width: 100%;
  margin: 20px 100px;
  color: whitesmoke;
  background-color: black;
  opacity: 0.7;
}

.portfolio .square {
  clear: left;
}

/* END OF PORTFOLIO THEME */

/* BLOG THEME */

.blog-post {
  width: 100%;
  max-width: 900px;
  margin: 50px auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: 	#303030;
  color: whitesmoke;
  border-radius: 10px;
  padding: 10px;
}

/* text formatting */
.blog-post h2{
  border-bottom: 3px solid tomato;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.blog-post p, .blog-subtitle, .blog-title {
  height: 100%;
  padding: 10px;
  white-space:normal;
}

.blog-post li {
  padding: 5px;
}

.content-img {
  margin-left: 100px;
}

/* expandable blog post feature */
.collapsible {
  background-color: #303030;
  color: none;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: left;
  outline: none;
  border: 0px;
  font-size: 15px;
  transition: height 2s;
}

.active-button {
  display: block;
}


.content {
  max-height: 0px;
  overflow: hidden;
  transition: all 1s;
}

.content-open {
  max-height: 5000px;
  transition: all 1s;
}


/* Code block styling  */

code {
  font-family: monospace;
  font-size: inherit;
  border-radius: 10px 5px;
}

/* Code in text */
p > code,
li > code,
dd > code,
td > code {
  background: #ffeff0;
  word-wrap: break-word;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  padding: .1rem .3rem .2rem;
  border-radius: .2rem;
}

pre code {
  display: block;
  background: grey;
  white-space: pre;
  overflow-y: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 250px;
  min-width: 100px;
  padding: 25px;
}

/* mobile first */

@media only screen and (max-width: 600px){
  /* fonts  */
  h2{
      font-size: 1rem;
  }

  p, ul {
      font-size: 1rem;
  }

  .nav-menu h2 {
      font-size: 2rem;
  }

  /* navigation */
  .contacts {
      display: none;
  }

  .nav {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      flex-direction: column;
  }

  .nav-icons {
      height: 50px;
      width: 100vw;
      text-align: right;
  }

  .nav-icons p{
      display: inline-block;
      margin-right: 25px;
  }

  .nav-menu {
      height: 0px;
      transition:  height 1s;
  }

  .nav-menu-open {
    height: 300px;
    transition:  height 1s;
}

  /* main content */

  .main-content {
      margin-left: 0;
      padding: 0;
  }

  .square {
      width: 100%;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      padding-bottom: 20px;
      margin-left: 0;
      margin-right: 0;
      border-radius: 0%;

  }

  .square i {
      font-size: 3em;
      margin: 20px;
      margin-left: 50px;
  }

  #footer {
      display: block;
      margin-bottom: 20px;
  }

  #footer-contacts {
    display: flex;
    width: 100vw;
  }

  #footer-contacts i {
      color: white;
      font-size: 2rem;
      transition: color 1s;
      margin-left: 20vw;
  }

  #footer-contacts i:active  {
      color: tomato;
  }

  .blank {
      height: 10px;
  }

  /* MOBILE 1ST: PORTFOLIO STYLING */

  .portfolio {
    margin-top: 50px;
  }
  .portfolio-category {
      margin: 20px 0px;
      padding-left: 10px;
  }

  /* MOBILE 1ST: BLOG POST STYLING */

  .blog {
    margin-top: 100px;
  }

  .blog-post {
    border-radius: 0px;
  }


}

/* imported CSS animations */

* {
  box-sizing: border-box;
}

.link {
  height: 30px;
  margin-left: 10%;
  align-items: center;
  color: whitesmoke;
  text-decoration: none;
  display: flex;
}

/* Hide extra text */
.mask {
  position: relative;
  padding: 0;
  height: 20px;

  /*  Remove overflow to see how it works　:) */
  overflow: hidden;
}

.link-container {
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.title {
  display: block;

  /*  Set same font-size and line height  */
  font-size: 20px;
  line-height: 20px;

  transition: -webkit-transform 0.4s ease;

  transition: transform 0.4s ease;

  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.link-title1 {
  -webkit-transform-origin: right center;
          transform-origin: right center;
}

.link-title2 {
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

.link-icon {
  position: relative;
  width: 35px;
  height: 35px;
  background: #f8f8ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  /*  Remove overflow to see how it works　:) */
  overflow: hidden;
}

.icon {
  display: block;
  position: absolute;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.icon:nth-child(2) {
  -webkit-transform: translate(-40px);
          transform: translate(-40px);
}

/* Hover Action*/

/* Move up two texts (20px = font-size) */
.link:hover .link-container {
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}

/* Rotate texts a little bit */
.link:hover .link-title1 {
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

.link:hover .link-title2 {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

/* Move arrows to right */
.link:hover .icon:first-child {
  -webkit-transform: translate(40px);
          transform: translate(40px);
}

.link:hover .icon:nth-child(2) {
  -webkit-transform: translate(0px);
          transform: translate(0px);
}

